
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';

@Injectable()
export class InvoiceService {
    apiUrl: string = environment.apiUrl;
    constructor(private http: HttpClient) { }

    list() {
        return this.http
        .get<any>(this.apiUrl + '/invoice/list').pipe(
        map(res => res));
      }
}
