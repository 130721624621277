// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.https://api-v1-dev.pplplus.org/
import 'zone.js/dist/zone-error';

//staging machine setup
export const environment = {
  production: false,
//  apiUrl: 'https://api-dev.pplplus.org',
//  RapiUrl: 'https://api-dev.pplplus.org',
//  R2apiUrl: 'https://api-dev.pplplus.org'

 apiUrl: 'https://api-v1-dev.pplplus.org',
 RapiUrl: 'https://api-v1-dev.pplplus.org',
 R2apiUrl: 'https://api-v1-dev.pplplus.org'
};


//local machine setup
// export const environment = {
//   production: false,
//   apiUrl: 'http://ppl-dev.local',
//   RapiUrl: 'http://ppl-dev.local',
//   R2apiUrl: 'http://ppl-dev.local'

// };