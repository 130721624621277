import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';

@Injectable()
export class TrackingService {
    apiUrl: string = environment.apiUrl;
    RapiUrl: string = environment.RapiUrl;
    R2apiUrl: string = environment.R2apiUrl;

    constructor(private http: HttpClient) { }

     list(params:any={}) {
        return this.http

        .get<any>(this.R2apiUrl + '/tracking/trackinglist?',{params:params}).pipe(

        map(res => res));
      }
     
}      