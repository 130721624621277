
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';

@Injectable()
export class EinvoicectService {
  apiUrl: string = environment.apiUrl;
  RapiUrl: string = environment.RapiUrl;
  R2apiUrl: string = environment.R2apiUrl;

  constructor(private http: HttpClient) { }

  list(params = '') {

    return this.http
      .get<any>(this.RapiUrl + '/Einvoicect/list?' + params).pipe(
        map(res => res));

  }
}
