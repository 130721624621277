import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {AdminComponent} from './layout/admin/admin.component';
import {AuthComponent} from './layout/auth/auth.component';
import { AuthGuard } from './services/auth.guard';

const routes: Routes = [
  {
    path: '',
    component: AdminComponent,
    children: [
      {
        path: '',
        redirectTo: 'quotation',
        pathMatch: 'full'
      },
      {
        path: 'dashboard',
        loadChildren: './components/dashboard/dashboard.module#DashboardModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'customer',
        loadChildren: './components/customer/customer.module#CustomerModule'
      },
      {
        path: 'user',
        loadChildren: './components/user/user.module#UserModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'licensecategory',
        loadChildren: './components/licensecategory/licensecategory.module#LicenseCategoryModule'
      },
      {
        path: 'city',
        loadChildren: './components/city/city.module#CityModule'
      },
      {
        path: 'metamaster',
        loadChildren: './components/metamaster/metamaster.module#MetamasterModule'
      },
     
      {
        path: 'quotation',
        loadChildren: './components/quotation/quotation.module#ApplicationModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'creditnote',
        loadChildren: './components/creditnote/creditnote.module#CreditnoteModule',
        canActivate: [AuthGuard]
      },
      { 
        path: 'branch',
        loadChildren: './components/branch/branch.module#BranchModule'
      },
      {
        path: 'license',
        loadChildren: './components/license/license.module#LicenseModule'
      },
      {
        path: 'role',
        loadChildren: './components/role/role.module#RoleModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'setting',
        loadChildren: './components/setting/setting.module#SettingModule'
      },
     
      {
        path: 'transaction',
        loadChildren: './components/transaction/transaction.module#TransactionModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'report',
        loadChildren: './components/report/report.module#ReportModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'document',
        loadChildren: './components/document/document.module#DocumentModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'incentive',
        loadChildren: './components/incentive/incentive.module#IncentiveModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'target',
        loadChildren: './components/target/target.module#TargetModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'leadstatus',
        loadChildren: './components/leadstatus/leadstatus.module#LeadstatusModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'tracking',
        loadChildren: './components/tracking/tracking.module#TrackingModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'einvoicect',
        loadChildren: './components/einvoicect/einvoicect.module#EinvoicectModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'lead',
        loadChildren: './components/lead/lead.module#LeadModule',
        
      },
      {
        path: 'dtoc',
        loadChildren: './components/dtoc/dtoc.module#DtocModule',
        canActivate: [AuthGuard]
      },
    ]
  },
  {
    path: '',
    component: AuthComponent,
    children: [
      {
        path: 'auth',
        loadChildren: './components/auth/auth.module#AuthModule'
      },/*
      {
        path: 'email/email-template',
        loadChildren: './theme/email/email-template/email-template.module#EmailTemplateModule'
      },
      {
        path: 'maintenance/offline-ui',
        loadChildren: './theme/maintenance/offline-ui/offline-ui.module#OfflineUiModule'
      }*/
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
