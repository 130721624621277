import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';

@Injectable()
export class TargetService {
    apiUrl: string = environment.apiUrl;
    RapiUrl: string = environment.RapiUrl;
    R2apiUrl: string = environment.R2apiUrl;

    constructor(private http: HttpClient) { }

     list(params='') {
        return this.http
        .get<any>(this.R2apiUrl + '/target/list?'+params).pipe(
        map(res => res));
      }
      addEdit(role) {
        console.log(role);
           return this.http.post(this.apiUrl + '/target/addedit',role).pipe(
           map(res => res));
       }

       resetTargets(role) {
        console.log(role);
           return this.http.post(this.apiUrl + '/target/resettargets',role).pipe(
           map(res => res));
       }

}      