import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { AdminComponent } from './layout/admin/admin.component';
import { AuthComponent } from './layout/auth/auth.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {SharedModule} from './shared/shared.module';
import {MenuItems} from './shared/menu-items/menu-items';
import {BreadcrumbsComponent} from './layout/admin/breadcrumbs/breadcrumbs.component';
import { AgmCoreModule } from '@agm/core';
// import DTocComponent from './components/dtoc/dtoc.component';
 

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptor } from './services/token.interceptor';
import { AuthGuard } from './services/auth.guard';
import {SimpleNotificationsModule } from 'angular2-notifications';
import { AlertService, AuthService, CustomerService, UserService,
  LicenseCategoryService, BranchService, CommonService, QuotationService, TransactionService, MasterService,
  InvoiceService, RoleService,DocumentService,LeadstatusService,LeadService,TargetService, TrackingService,DashboardService,SettingService,EinvoicectService} from './services/index';
import { DtocService } from './components/dtoc/dtoclog.service';
// import { DtocComponent } from './components/dtoc/dtoc.component';


@NgModule({
  declarations: [
    AppComponent,
    AdminComponent,
    AuthComponent, 
    BreadcrumbsComponent, 
  ],
  imports: [
    BrowserModule, 
    FormsModule, ReactiveFormsModule,

    //Testing GPS api 
    // AgmCoreModule.forRoot(  {
    //   apiKey: 'AIzaSyCRpc8mRnV1coBowtKWw7Jr5MDCMkODbDI',
    // libraries: ['places']
    // }),

    // Google Maps API key in PPL's google account
    AgmCoreModule.forRoot(  {
      apiKey: 'AIzaSyCWTXhw6HKIlWY33OpKMvhCCmSh4i_uu-0',
    libraries: ['places']
    }),

    BrowserAnimationsModule,
    AppRoutingModule, 
    SharedModule,
    SimpleNotificationsModule.forRoot()
  ],
  providers: [
    AuthGuard,
    AlertService, AuthService, CustomerService, UserService,DocumentService,LeadService,LeadstatusService,MasterService,
  LicenseCategoryService, BranchService, CommonService, QuotationService, InvoiceService , RoleService , TargetService,
  TransactionService , TrackingService, DashboardService,SettingService, EinvoicectService, MenuItems, DtocService,
  
  {
    provide: HTTP_INTERCEPTORS,
    useClass: TokenInterceptor,
    multi: true
  }],
  bootstrap: [AppComponent]
})
export class AppModule { }
