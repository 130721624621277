
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';

@Injectable()
export class UserService {
    apiUrl: string = environment.apiUrl;
    RapiUrl: string = environment.RapiUrl;
    R2apiUrl: string = environment.R2apiUrl;

    constructor(private http: HttpClient) { }

    list(params='') {
    
        return this.http
        .get<any>(this.R2apiUrl + '/user/list?' + params).pipe(
        map(res => {
            //   console.log(quotation);
                sessionStorage.setItem('users',JSON.stringify(res));
                return res;
           }));
      }

      
       addEdit(selectedUser) {
       console.log(selectedUser);
       return this.http.post<any>(this.apiUrl + '/user/addedit',  selectedUser ).pipe(
       map(user => {
        //   console.log(quotation);
            return user;
       }));
      }

      freezlist(){
        return this.http
        .get<any>(this.R2apiUrl + '/user/userfrzList').pipe(
        map(res => {
            //   console.log(quotation);
                //sessionStorage.setItem('users',JSON.stringify(res));
                return res;
           }));
      }

      defreezuser(userid) {
        return this.http.post<any>(this.apiUrl + '/user/DefreezUser ',  { id: userid}).pipe(
        map(user => {
         //   console.log(quotation);
             return user;
        }));
       } 

       motherchildregion() {
        return this.http.get<any>(this.R2apiUrl + '/user/motherZones').pipe(
        map(res => res));
      }

      getchildregion(region) {
          console.log(region);
        return this.http.post<any>(this.R2apiUrl + '/user/ChildZonesByregion', { region: region}).pipe(
        map(res => {
                return res;
           }));
      }
}
