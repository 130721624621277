
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';

@Injectable()
export class MasterService {
    apiUrl: string = environment.apiUrl;
    RapiUrl: string = environment.RapiUrl;
    R2apiUrl: string = environment.R2apiUrl;

    constructor(private http: HttpClient) { }

    listcity(params='') {
        return this.http
        .get<any>(this.RapiUrl + '/master/listcity?'+params).pipe(
        map(res => res));
      }

      listmetamaster(params='') {
        return this.http
        .get<any>(this.RapiUrl + '/master/listmetamaster?'+params).pipe(
        map(res => res));
      }

      listmetatypes(params='') {
        return this.http
        .get<any>(this.RapiUrl + '/master/listmetatypes?'+params).pipe(
        map(res => res));
      }

      listeventvenuetag(params='') {
        return this.http
        .get<any>(this.RapiUrl + '/master/listeventvenuetag?'+params).pipe(
        map(res => res));
      }
      

    citysearch(term : any='0') {
        return this.http
        .get<any>(this.RapiUrl + '/master/citysearch?q='+term).pipe(
        map(res => res));
      }

      metasearch(term : any='0',type:any='') {
        term=encodeURIComponent(term);
        type=encodeURI(type);
        return this.http
        .get<any>(this.R2apiUrl + '/master/metasearch?q='+term+'&type='+type).pipe(
        map(res => res));
      }

      addeditcity(customer) {  
        return this.http.post(this.apiUrl + '/master/addeditcity',customer).pipe(
        map(res => res));
      }
      
      addeditmetamaster(customer) {  
        return this.http.post(this.apiUrl + '/master/addeditmetamaster',customer).pipe(
        map(res => res));
      }

      deletecity(params='') {
        
        return this.http
        .get<any>( this.apiUrl + '/master/deletecity/?'+params).pipe(
        map(res => res));
  
      }


      deletemetamaster(params='') {
        
        return this.http
        .get<any>( this.apiUrl + '/master/deletemetamaster/?'+params).pipe(
        map(res => res));
  
      }

      searcheventname(term : any='') {
        console.log("event name", term);
        return this.http
        .get<any>(this.RapiUrl + '/Quotation/EventnameSearch?q='+term).pipe(
        map(res => res));
      }
}  