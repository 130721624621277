
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';

@Injectable()
export class LicenseCategoryService {
    apiUrl: string = environment.apiUrl;
    RapiUrl: string = environment.RapiUrl;
    R2apiUrl: string = environment.R2apiUrl;

    constructor(private http: HttpClient) { }

    list() {
        return this.http
        .get<any>(this.R2apiUrl + '/licensecategory/list').pipe(
        map(res => {
            //   console.log(quotation);
                sessionStorage.setItem('lcs',JSON.stringify(res));
                return res;
           }));
      }
}
