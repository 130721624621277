
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';

@Injectable()
export class LeadstatusService {
    apiUrl: string = environment.apiUrl;
    RapiUrl: string = environment.RapiUrl;
    constructor(private http: HttpClient) { }

    list(params='') {
        
        return this.http
        .get<any>( this.RapiUrl + '/leadstatus/list?'+params).pipe(
        map(res => res));

      }

      listQuestions(params='') {
        
        return this.http
        .get<any>( this.RapiUrl + '/leadstatus/questions?'+params).pipe(
        map(res => res));

      }

      addEdit(role) {
        console.log(role);
           return this.http.post(this.apiUrl + '/leadstatus/addedit',role).pipe(
           map(res => res));
       }

       addEditLeadStatusQuestion(role) {
        console.log(role);
           return this.http.post(this.apiUrl + '/leadstatus/addeditleadstatusquestion',role).pipe(
           map(res => res));
       }

    }