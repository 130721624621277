
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';

@Injectable()
export class LeadService {
  apiUrl: string = environment.apiUrl;
  RapiUrl: string = environment.RapiUrl;
  constructor(private http: HttpClient) { }

  list(params = '') {

    return this.http
      .get<any>(this.RapiUrl + '/lead/list?' + params).pipe(
        map(res => res));

  }

  leadsearch(params = '') {

    return this.http
      .get<any>(this.RapiUrl + '/lead/leadsearch?' + params).pipe(
        map(res => res));

  }
  // lead ques.
  leadstatusdata(params = '') {

    return this.http
      .get<any>(this.RapiUrl + '/lead/leadstatusdata?' + params).pipe(
        map(res => res));
  }
}