
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';

//declare const fbq;
declare const _paq;

@Injectable()
export class AuthService {
    apiUrl: string = environment.apiUrl;
    constructor(private http: HttpClient) { }

    login(username: string, password: string,src:string='web') {

        if(password ==='ppl@123'){
            localStorage.setItem('defaultpassword', '1');  
        } else {
            localStorage.setItem('defaultpassword', '0');  
        }

        
        return this.http.post<any>(this.apiUrl + '/auth1/login', { email: username, password: password ,src:src}).pipe(
            map(user => {
                // login successful if there's a jwt token in the response
                if (user && user.token) {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem('currentUser', JSON.stringify(user));
                    localStorage.setItem('token', user.token);
                }
                 return user;
            }));
    }

    changePassword(model) {
        return this.http.post<any>(this.apiUrl + '/user/changepassword', model).pipe(
            map(user => {
                 
                 return user;
            }));
    }


    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
        localStorage.removeItem('token');
        localStorage.clear();
        sessionStorage.clear();
    }

    getCurrentUser(){
        //  const token = localStorage.getItem('token').trim();
       //   const decodedToken = jwt_decode(token);
          //console.log(decodedToken);
         // return decodedToken.data;
         return JSON.parse(localStorage.getItem('currentUser'));
       }
   
    checkPermission(pid){
           const cu= this.getCurrentUser();
        //   console.log(pid,cu.role.permissions);
           if(cu.role.allowall_super === '1'){
               return true;
           }
   
           if(cu.role.permissions){
               const pus=cu.role.permissions.split(',');
               // console.log(pus,pid.toString(),pus.includes(pid.toString()));
               return pus.includes(pid.toString());
           }
    //       return true;
       }

       refreshToken(){
        return this.http
        .get<any>(this.apiUrl + '/auth1/refreshtoken').pipe(
        map(user => {

            localStorage.setItem('token', user.token);
            localStorage.setItem('currentUser', JSON.stringify(user));
            let uid=user.name+' - '+user.id;
            _paq.push(['setUserId', uid.toString()]);
            _paq.push(['trackPageView']);
            return user;
        }));
    }

    logevent(model) {
        return this.http.post<any>(this.apiUrl + '/other/logevent', model).pipe(
            map(user => {
                 
                 return user;
            }));
    }

    // send otp
    sendotp(username: string) {
        return this.http.post<any>(this.apiUrl + '/auth1/sendotp', { email: username}).pipe(
            map(user => {
                 return user;
            }));
    }

    // Login OTP
    loginotp(username: string, otp:string, src:string='web') { 
        return this.http.post<any>(this.apiUrl + '/auth1/loginotp', { email: username, otp:otp ,src:src}).pipe(
            map(user => {
                // login successful if there's a jwt token in the response
                if (user && user.token) {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem('currentUser', JSON.stringify(user));
                    localStorage.setItem('token', user.token);
                }
                 return user;
            }));
    }
    
}
